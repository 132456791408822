import React from 'react';

// Components
import Layout from 'components/layout';
import ContactUs from 'components/contact-us';

// Styles
import * as styles from './contact-us.module.scss';

const ContactUsPage = () => {
  const description = `Please get in touch, and we will reply as soon as possible
      Adress:  Moianès, 13 - Pol. Ind. Can Casablanques 08192 Sant Quirze del Vallès (Barcelona) Spain
      Phone: +34 93 265 55 22
      https://www.indiba.com/contact/`;

  return (
    <Layout title="Contact Us" meta={{ description, image: 'https://intelis-public.s3.us-east-2.amazonaws.com/image-device.png' }}>
      <div className={styles.Wrapper}>
        <ContactUs />
      </div>
    </Layout>
  );
};

export default ContactUsPage;
